.c-pmp-footer {
  width: 100%;
  min-height: 160px;
  background: #eef3f6;
  background: var(--theme-footer-background-color);
  color: #000;
  color: var(--theme-footer-text-color);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.c-pmp-footer__nav {
  width: 100%;
  max-width: 1280px;
  padding: 32px;
  margin: 0 auto;
  border-bottom: 1px solid #ddd;
  display: table;
  table-layout: fixed;
}
.c-pmp-footer__nav__box {
  vertical-align: top;
  display: table-cell;
}
.c-pmp-footer__nav__box:first-child {
  width: 240px;
}
.c-pmp-footer__nav__box__ttl {
  font-size: 16px;
  font-weight: bold;
}
.c-pmp-footer__nav__box__list {
  /*
  display: flex;
  flex-wrap: wrap;
  */
}
.c-pmp-footer__nav__box__list__item {
  margin-top: 8px;
  margin-right: 32px;
}
.c-pmp-footer__nav__box__list__item a {
  text-decoration: underline;
}
.c-pmp-footer__info_sns_icons {
  width: 60%;
}
.c-pmp-footer__info {
  width: 100%;
  padding: 32px;
  display: flex;
  justify-content: flex-start;
}
.c-pmp-footer__info__section {
  margin-right: 32px;
}
.c-pmp-footer__info__section p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pmp-footer__info__section p + p {
  margin-top: 16px;
}
.c-pmp-footer__info__section img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.c-pmp-footer__info__section a {
  text-decoration: underline;
}
.c-pmp-footer__info__section span {
  width: calc(100% - 24px - 4px);
}
.c-pmp-footer__rule {
  width: 40%;
  padding: 16px 32px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.c-pmp-footer__rule__logo {
  color: #49a7cc;
  font-weight: bold;
  margin-bottom: 16px;
}
.c-pmp-footer__rule__logo a {
  width: 72px;
  margin-left: 16px;
  display: inline-block;
}
.c-pmp-footer__rule__list {
  max-width: 640px;
  color: #49a7cc;
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.c-pmp-footer__rule__list li + li {
  margin-left: 16px;
}
.c-pmp-footer__copy {
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background: #0094cc;
  background: var(--theme-primary-color);
}
@media screen and (max-width: 768px) {
  .c-pmp-footer__custom__links {
    margin-top: 30px;
  }
  .c-pmp-footer__nav {
    padding: 16px;
    display: block;
  }
  .c-pmp-footer__nav__box {
    display: block;
  }
  .c-pmp-footer__nav__box:first-child {
    width: 100%;
  }
  .c-pmp-footer__nav__box + .c-pmp-footer__nav__box {
    margin-top: 16px;
  }
  .c-pmp-footer__info_sns_icons {
    width: 100%;
  }
  .c-pmp-footer__info {
    width: 100%;
    padding: 16px;
    flex-direction: column;
  }
  .c-pmp-footer__info__section + .c-pmp-footer__info__section {
    margin-top: 16px;
  }
  .c-pmp-footer__rule {
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .c-pmp-footer__rule__list {
    margin-bottom: 16px;
    justify-content: flex-start;
  }
  .c-pmp-footer__rule__list li + li {
    margin-left: 16px;
    margin-right: 16px;
  }
}
